@import '~@trussworks/react-uswds/lib/index.css';

.site-prose {
    max-width: 100%;
}

.site-prose * {
    max-width: 100% !important;
}

.site-form {
    max-width: 100%;
}
