.chat-box {
    border: 1px solid black;
    padding: 0.5rem;
    margin-top: 0.5rem;
    height: 30rem;
    overflow-y: scroll;
}

.chat-input {
    max-width: 100%;
}

.chat-message {
    line-height: 1.25rem;
}

.chat-markdown p:first-child {
    margin-top: 0;
}
